import { safeAbsurd } from '~/utils/safeAbsurd';

import type { Collaboration } from '../../../collaborations/types';
import type { ServerDrivenComponent } from '../../../serverDrivenUI/components/SDComponent';
import type { User } from '../../../users/types';
import type { TrackingData, TrackingItem } from '../../core/types/trackingItem';

export type AppEvents = 'signup-start' | 'signup-success' | 'signup-error';
export type CollaborationEvents = 'send-state-success' | 'submit-success' | 'toggle-smart-sorting';

type CollaborationEvent =
  | {
      type: 'collaboration-event';
      name: 'send-state-success' | 'submit-success';
      data: {
        collaboration: Collaboration;
        component?: ServerDrivenComponent;
        fromSmartSorting?: boolean;
      } & TrackingData;
    }
  | {
      type: 'collaboration-event';
      name: 'toggle-smart-sorting';
      data: {
        toState: 'enabled' | 'disabled';
        source: 'default' | 'default-with-overlay' | 'lets-try';
      };
    }
  | {
      type: 'collaboration-event';
      name: 'rehire-drawer-closed';
      data: {
        collaborationId: string;
      };
    }
  | {
      type: 'collaboration-event';
      name: 'rehire-drawer-opened';
      data: {
        collaborationId: string;
      };
    }
  | {
      type: 'collaboration-event';
      name: 'rehire-step-changed';
      data: {
        collaborationId: string;
        toStep: string;
      };
    }
  | {
      type: 'collaboration-event';
      name: 'rehire-completed';
      data: {
        collaborationId: string;
      };
    };

export type AppTrackingItem =
  | { type: 'event'; name: AppEvents; data?: TrackingData }
  | CollaborationEvent
  | {
      type: 'collaborations-event';
      name: CollaborationEvents;
      data: { collaborationIds: Collaboration['id'][]; component?: ServerDrivenComponent } & TrackingData;
    }
  | { type: 'mainUser'; user: User }
  | { type: 'pageView'; path: string; name?: string };

export function convertAppTrackingItemToTrackingItem(appTrackingItem: AppTrackingItem): TrackingItem {
  switch (appTrackingItem.type) {
    case 'collaboration-event':
      return {
        type: 'event',
        name: `collaboration.${appTrackingItem.name}`,
        data: (() => {
          const name = appTrackingItem.name;
          switch (name) {
            case 'send-state-success':
            case 'submit-success':
              return {
                ...getCollaborationTrackingData(appTrackingItem.data.collaboration),
                ...(appTrackingItem.data.component
                  ? getServerDrivenComponentTrackingData(appTrackingItem.data.component)
                  : {}),
                from_smart_sorting: appTrackingItem.data.fromSmartSorting,
              };
            case 'toggle-smart-sorting':
              return {
                to_state: appTrackingItem.data.toState,
                source: appTrackingItem.data.source,
              };
            case 'rehire-drawer-opened':
            case 'rehire-completed':
            case 'rehire-drawer-closed':
              return {
                collaboration_id: appTrackingItem.data.collaborationId,
              };
            case 'rehire-step-changed':
              return {
                collaboration_id: appTrackingItem.data.collaborationId,
                to_step: appTrackingItem.data.toStep,
              };
            default:
              return safeAbsurd(name, {}, 'invalid_app_tracking_collaborations_item_name');
          }
        })(),
      };
    case 'collaborations-event':
      return {
        type: 'event',
        name: `collaborations.${appTrackingItem.name}`,
        data: {
          ...getCollaborationsTrackingData(appTrackingItem.data.collaborationIds),
          ...(appTrackingItem.data.component
            ? getServerDrivenComponentTrackingData(appTrackingItem.data.component)
            : {}),
        },
      };
    case 'mainUser':
      return {
        type: 'identify',
        user: appTrackingItem.user,
      };

    default:
      return appTrackingItem;
  }
}

function getCollaborationTrackingData(collaboration: Collaboration): TrackingData {
  return {
    collaboration_id: collaboration.id,
    collaboration_campaign_id: collaboration.campaignId,
    creator_id: collaboration.creator.id,
    creator_email: collaboration.creator.email,
    collaboration_state_name: collaboration.state.name,
    collaboration_state_metadata: collaboration.state.metadata,
  };
}

function getCollaborationsTrackingData(collaborationIds: Collaboration['id'][]): TrackingData {
  return {
    collaborations_id: collaborationIds.join(','),
  };
}

function getServerDrivenComponentTrackingData(component: ServerDrivenComponent): TrackingData {
  switch (component.type) {
    case 'button':
      return {
        action: component.action,
        action_title: component.title,
      };
    default:
      return {};
  }
}
